import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import BoardScreen from './component/Classscreen';
import Homescreen from './component/Homescreen';
import Classscreen from './component/Classscreen';
import Subjectscreen from './component/Subject';
import CourseList from './component/Courceslist';
import LoginPage from './component/Login';
import RegisterPage from './component/Register';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Homescreen />} />
      <Route path="/Classscreen" element={<Classscreen />} />
        <Route path="/Subjectscreen" element={<Subjectscreen />} />
        <Route path="/CourseList" element={<CourseList/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
