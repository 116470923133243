import React, { useState } from 'react';
import { Container, Typography, TextField, Button, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import Header from './Header';
import ApiService from '../services/api';
import { useLocation, useNavigate, Link } from 'react-router-dom';

const StyledContainer = styled(Container)({
  marginTop: theme => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: theme => theme.spacing(1),
});

const useStyles = {
  submit: {
    margin: theme => theme.spacing(3, 0, 2),
  },
};

const LoginPage = () => {
  
  
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState();
  
  const location = useLocation();


  // Access parameters using the location state
  const params = location.state;

  const handleLogin = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log(`Username: ${username}, Password: ${password}`);
    
    const userLogin = async ()=>{
      try{

        const user = await  ApiService.post('login',  { email:username, password });
        console.log("yser", user)
        localStorage.setItem('access_token', user?.data?.access_token);        
        localStorage.setItem('user', JSON.stringify(user?.data?.user));        
        alert("Login successfull");
        if(params){

          navigate('/CourseList', {state:params});
        }else{
          navigate('/');
        }
      } catch(error){
        console.log("error", error); 
        if (error.response && error.response.data && error.response.data.error) {
          // If the server provides a specific error message
          alert(error.response.data?.error || "Login failed. Please try again later.");
      } else {
          // If no specific message is available, show a generic error
          alert("Login failed. Please try again later.");
      }
      }

    };
    userLogin();
  };

  return (
    <div className="App" style={{ background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)' }}>

    <Header />
    <StyledContainer component="main" maxWidth="xs">
        <br />
        <br />
        <br />
      <CssBaseline />
      <div>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <StyledForm onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={useStyles.submit}
          >
            Sign In
          </Button>
          {/* <Link href="#" variant="body2">
            Forgot Password?
          </Link> */}
          <Link to={"/register"} >
            {"Don't have an account? Sign Up"}
          </Link>
        </StyledForm>
      </div>
    </StyledContainer>
    <br />
        <br />
        <br />
    
    </div>
  );
};

export default LoginPage;
