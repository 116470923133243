// Header.js
import React from 'react';
import './Header.css';
import { Link, Navigate, useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  let user = localStorage.getItem('user');
  if(user){
    user = JSON.stringify(user);
  }

  const callToLogout= ()=>{
    localStorage.removeItem("user");
    localStorage.removeItem('access_token');
    navigate("/");
  }

  return (
    <header className="App-header">
      <div className="container">
        <div className="header-content">
        <Link to={"/"} style={{color:"#ffffff", textDecoration:'auto'}}>
          <h1>Shree Vaishnav Institute of Education Excellence</h1>
        </Link>
        {
          user?(
            <Link  style={{color:"#ffffff", textDecoration:'auto'}}>
          <button className="login-button" onClick={()=>callToLogout()}>Logout</button>
          </Link>
        ):(
          <Link to={"/login"} style={{color:"#ffffff", textDecoration:'auto'}}>
          <button className="login-button">Login</button>
        </Link>)
        }
        </div>
      </div>
    </header>
  );
}

export default Header;
