// Subjectscreen.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Subject.css';

const subjects = [
  { id: 1, name: 'Science', link: '/board/board1/' },
  { id: 2, name: 'Mathematics', link: '/board/board2/' },
  { id: 3, name: 'Biology', link: '/board/board3/' },
  { id: 4, name: 'English', link: '/board/board4/' },
];

function Subjectscreen() {
  return (
    <div className="SubjectApp">
      <header className="SubjectApp-header">
        <div className="container">
          <div className="header-content">
            <h1>Shree Vaishnav Institute of Education and Excellence</h1>
            <button className="login-button">Login</button>
          </div>
        </div>
      </header>
      <div className="SubjectAdditionalDiv">
        <div className="text-content">
          <p className="board-text">Select subjects</p>
        </div>

        <div className="SubjectSquareDivContainer">
          {subjects.map((subject) => (
            <Link key={subject.id} to={subject.link} className="SubjectSquareDiv">
              <p>{subject.name}</p>
              <button className="SubjectClickButton">Click Here</button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Subjectscreen;
