import React, { useState } from 'react';
import { Container, Typography, TextField, Button, CssBaseline, Alert } from '@mui/material';
import { styled } from '@mui/system';
import Header from './Header';
import ApiService from '../services/api';
import { useNavigate, Link } from 'react-router-dom';

const StyledContainer = styled(Container)({
  marginTop: theme => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: theme => theme.spacing(1),
});

const useStyles = {
  submit: {
    margin: theme => theme.spacing(3, 0, 2),
  },
};

const RegisterPage = () => {
  
  
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleRegister = (e) => {
    e.preventDefault();
    // Add your registration logic here
    console.log(`Name: ${name}, Email: ${email}, Mobile: ${mobile}, Password: ${password}`);
    if(password  !== confirmPassword){
      return alert("Passwords do not match!");
    }

    const userRegister = async ()=>{
      try{

        const user = await  ApiService.post('register',  { name, email, mobile, password });
        alert("register successfull");
        navigate('/login');
      } catch(error){
        console.log("error", error); 
        if (error.response && error.response.data && error.response.data.message) {
          // If the server provides a specific error message
          alert(error.response.data?.message || "Registration failed. Please try again later.");
      } else {
          // If no specific message is available, show a generic error
          alert("Registration failed. Please try again later.");
      }
      }

    };
    userRegister();
  }

  return ( 
  <div className="App" style={{ background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)' }}>

  <Header />
  <StyledContainer component="main" maxWidth="xs">
      <br />
      <br />
      <br />
      <CssBaseline />
      <div>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <StyledForm onSubmit={handleRegister}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mobile"
            label="Mobile Number"
            name="mobile"
            autoComplete="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={useStyles.submit}
          >
            Register
          </Button>
          <Link to={"/login"} variant="body2">
            Already have an account? Sign In
          </Link>
        </StyledForm>
      </div>
    <br />
    <br />
    <br />
    </StyledContainer>
    </div>

  );
};

export default RegisterPage;
