// CourseList.js
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Header from './Header';

import { useNavigate, useLocation } from 'react-router-dom';
import ApiService from '../services/api';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, CardMedia, Typography, Grid, Select, MenuItem, CircularProgress, Box, Button } from '@mui/material';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CourseList = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  const location = useLocation();
  const navigate = useNavigate();


  // Access parameters using the location state
  const params = location.state;
  console.log("asddasdas",params)
  
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const[filter, setFilter]=useState({
    boards:params?.boards || 0,
    standards:params?.standards || 0,
    subjects:params?.subjects || 0,
  })

  const [data, setData] = useState({boards:[], subjects:[], standards:[]});
  
  useEffect(()=>{
    const callApi = async ()=>{
      try{

        const study = await ApiService.get('study-materials', {params:filter, headers:{
          'Authorization':'Bearer '+localStorage.getItem('access_token')
        }});
        setCourses(study?.data?.data)
        setLoading(false)
      } catch(error){
        setLoading(false)
        console.log(error);
        alert("Unauthorized user");
        navigate('/login');

      }
    }
    callApi();

    
  }, [filter])

  useEffect(()=>{
    const callFilterApi = async ()=>{
      const [boards, standards, subjects] = await Promise.allSettled([ApiService.get('education-boards'), ApiService.get('standards'), ApiService.get('subjects')])
      if(boards.status == 'fulfilled'){
        setData((prev)=>({ ...prev, boards:boards?.value?.data?.data}))
      }
      if(standards.status == 'fulfilled'){
        setData((prev)=>({ ...prev, standards:standards?.value?.data?.data}))
      }
      if(subjects.status == 'fulfilled'){
        setData((prev)=>({ ...prev, subjects:subjects?.value?.data?.data}))
      }
    }
    callFilterApi();
  }, [])

  const handleFilterChange = (value, field)=>{
    setFilter({...filter,[field]: value});
    
    setLoading(true)
  }

    return (
        <div className="App" style={{ background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)',height: '700px' }}>
          <Header />
          <div className="container mt-4">
            <h2 className="mb-4">Course List</h2>
            <div className='row'>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Select fullWidth value={filter.boards} onChange={(e)=>handleFilterChange(e.target.value, 'boards')}>
                    <MenuItem value={0}>Select Board</MenuItem>
                    {data?.boards?.map((b) => (
                      <MenuItem key={b.id} value={b.id}>
                        {b.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select fullWidth value={filter.standards} onChange={(e)=>handleFilterChange(e.target.value, 'standards')}>
                    <MenuItem value={0}>Select Standard</MenuItem>
                    {data?.standards?.map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select fullWidth value={filter.subjects} onChange={(e)=>handleFilterChange(e.target.value, 'subjects')}>
                    <MenuItem value={0}>Select Subject</MenuItem>
                    {data?.subjects?.map((sub) => (
                      <MenuItem key={sub.id} value={sub.id}>
                        {sub.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
            <div className="row mt-5">
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="300px">
              <CircularProgress />
            </Box>
              ) : courses.length?"":(<Card><CardContent><h4>No Data Found</h4></CardContent></Card>)}
              
             {courses.map((x, i)=>(<Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id={"panel"+i+"-header"}
                >
                  {x.title}
                </AccordionSummary>
                <AccordionDetails>
                <Card>
                  <Grid container spacing={2}>
                  {
                        x.video_link?(<Grid item xs={12} md={12}>
                      
                          <CardMedia
                            component="iframe"
                            height="315"
                            src={x.video_link}
                            title="YouTube Video"
                          />
                        </Grid>):''
                      }

                    <Grid item xs={12} md={6}>
                    <Slider {...settings}>
                       {
                        x.images?.map((img, j)=>(

                          <div>
                          <img src={img.url} alt="Slide 1" />
                        </div>
                           ))
                        }
                      </Slider>
                      
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {x.attachment.length == 0?'':(<h6>Download Attachment</h6>)}
                        {x.attachment?.map((a, n)=>(
                          <Button style={{marginLeft:'5px'}} variant="contained" target='_blank' href={a.original_url} download>File {++n}</Button> 
                        ))}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CardContent>
                        {/* Image */}
                        
                        {/* Title */}
                        <Typography variant="h5" component="div" mt={2}>
                          {x.short_description}
                        </Typography>
                        
                        {/* Description */}
                        <Typography variant="body2" color="text.secondary" mt={2} dangerouslySetInnerHTML={{__html:x.description}}>
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
                </AccordionDetails>
              </Accordion>))}
            </div>
          </div>
        </div>
      );
};

export default CourseList;
