// ApiService.js

import axios from 'axios';

const API_BASE_URL = 'https://svieemanasa.in/admin/public/api/v1/'; // Replace with your API base URL

const ApiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // You can add additional headers here
  },
});

export default ApiService;
