// Homescreen.js
import React, { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import './Homescreen.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header';
import ApiService from '../services/api';
import { Button } from 'bootstrap';

function Homescreen() {
  
  const navigate = useNavigate();

  const [filter,setFilter] =useState({
    boards:"",
    standards:"",
    subjects:""
  });

  const [section, setSection] = useState("boards");
  
  const [data, setData] = useState({boards:[], subjects:[], standards:[]});

  useEffect(()=>{
    const callApi = async ()=>{
      const [boards, standards, subjects] = await Promise.allSettled([ApiService.get('education-boards'), ApiService.get('standards'), ApiService.get('subjects')])
      if(boards.status == 'fulfilled'){
        setData((prev)=>({ ...prev, boards:boards?.value?.data?.data}))
      }
      if(standards.status == 'fulfilled'){
        setData((prev)=>({ ...prev, standards:standards?.value?.data?.data}))
      }
      if(subjects.status == 'fulfilled'){
        setData((prev)=>({ ...prev, subjects:subjects?.value?.data?.data}))
      }
    }
    callApi();
    
  }, [])
  
  console.log(data)
  const changeFilter = (sec, x)=>{
    console.log(x, sec)
    let tmp = {};
    tmp[sec] = x.id;  
    setFilter(x=>({...x, ...tmp }));
    
    if(sec == 'boards'){
      setSection('standards');
    } else if (sec == 'standards'){
      setSection('subjects');
    } else if(sec =='subjects'){
      const user = localStorage.getItem('user');
      if(user){

        navigate('/CourseList', {state:{...filter, ...tmp}});
      } else {
        navigate('/login', {state:{...filter, ...tmp}});
      }
    }
  }


  return (
    <div className="App">
      <Header />
      <div className="additional-div container">
        
        <div className="text-content">
          <p className="board-text">Select Board</p>
        </div>
        <div className='row justify-content-center'>
          {
             data[section].map(x=>{
              if(section == 'boards'){

                return (<div className="col-md-4 custom-box text-center" key={x.id}  >
                    <div className=" rounded p-3 custom-box-image" >
                      {/* Image */}
                    </div>
                    {/* Text */}
                    <p className=" custom-box-text">{x.name}</p>
                    <button className="btn btn-primary" onClick={()=>changeFilter(section, x)}>
                      Click Here
                    </button>
                  </div>)
              } else if(section == 'standards'){
                return (<div className="col-md-4 custom-box text-center" key={x.id}  >
                    <div className=" rounded p-3 custom-box-image" >
                      {/* Image */}
                    <img src={"class.jpeg"} className=" mb-2" alt="Box Image" />
                    </div>
                    {/* Text */}
                    <p className=" custom-box-text">{x.label}</p>
                    <button className="btn btn-primary" onClick={()=>changeFilter(section, x)}>
                      Click Here
                    </button>
                  </div>)
              } else if (section == 'subjects'){
                return (<div className="col-md-3 custom-box text-center" key={x.id}  >
                    <div className=" rounded p-3 custom-box-image" >
                      {/* Image */}
                      <img src={"subject.jpeg"} className=" mb-2" alt="Box Image" />
                    </div>
                    {/* Text */}
                    <p className=" custom-box-text">{x.name}</p>
                    <button className="btn btn-primary" onClick={()=>changeFilter(section, x)}>
                      Click Here
                    </button>
                  </div>)
              }

                })
                
             
          }
        
          </div>


        {/* <div className="link-row">
          <Link to="/Classscreen" className="square-div" style={{ backgroundImage: 'url(/mpboard.png)' }}>
            <div className="square-div-content">
           
              <button className="click-button">Click Here</button>
            </div>
          </Link>
          <Link to="/Classscreen" className="square-div" style={{ backgroundImage: 'url(/cbseboard.png)' }}>
            <div className="square-div-content">
        
              <button className="click-button">Click Here</button>
            </div>
          </Link>
        </div> */}
        
      </div>

      
      <div className="about-us">
        <h2>About Us</h2>
        <h6>Our Mission</h6>
        <p>Shree Vaishnav Institute Of Education Of Excellence (SVIEE) C/O Mrs
          Chanda provide a national level study material free of cost for all
          students</p>
          <p>We are try to serve our best</p>
          <br />
        <p><b>Adress:</b> Rani laxmibai marg Manasa District Neemuch</p>
        <p><b>Contact:</b> 8085403600 </p>

      </div>
    </div>
  );
}

export default Homescreen;
